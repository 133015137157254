import { useQuery } from "react-query";
import {
  MomentoEvent,
  MomentoRecordingResult,
} from "@core/types/momento/Recording";
import { fetchApi, requireOk, toJson } from "~/services/fetchApi";

export function fetchRecordingEvent(id: number) {
  return fetchApi(`/momento/recordings/${id}`)
    .then(requireOk)
    .then<MomentoRecordingResult>(toJson);
}

export function useRecordings(eventId: number) {
  return useQuery(
    ["useRecordings", eventId],
    () => fetchRecordingEvent(eventId),
    {
      retry: false,
      refetchInterval: 1000 * 60,
    }
  );
}

export function fetchMomentoEvents() {
  return fetchApi(`/momento/events`)
    .then(requireOk)
    .then<MomentoEvent[]>(toJson);
}

export function useMomentoEvents() {
  return useQuery("useMomentoEvents", fetchMomentoEvents, {
    retry: false,
  });
}
