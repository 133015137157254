import styled from "@emotion/styled";

export const SimpleTable = styled.table`
  border-collapse: collapse;

  td,
  th {
    vertical-align: center;
    padding: 8px 12px;
  }

  thead th {
    margin-left: -12px;
  }

  td {
    border-top: 1px solid #dee2e6;
  }

  tr:nth-of-type(even) {
    background-color: #f8f9fa;
  }
`;
