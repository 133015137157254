import React from "react";
import { Link } from "gatsby";
import { useMomentoEvents } from "~/screens/momento/recordings/useRecordings";
import { MomentoLayout } from "~/layouts/momento/MomentoLayout";
import { SimpleTable } from "~/components/table/SimpleTable";

interface MomentoEventsScreenProps {}

export function MomentoEventsScreen(props: MomentoEventsScreenProps) {
  const { data: events } = useMomentoEvents();
  return (
    <MomentoLayout>
      <SimpleTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
          </tr>
        </thead>
        <tbody>
          {events?.map((event) => (
            <tr key={event.id}>
              <td>{event.id}</td>
              <td>
                <Link to={`/momento/recordings?event=${event.id}`}>
                  {event.title}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </SimpleTable>
    </MomentoLayout>
  );
}
